import { fetch } from './fetch.js'

export default {
    login(sys_u, sys_p) {
        return fetch('/api/backdoor/sys/login/', { sys_u, sys_p }, true)
    },
    goIndex() {
        return fetch('/api/backdoor/sys/query_app_list/', {}, true)
    },
    addApp(package_name, name, sys_platform) {
        return fetch('/api/backdoor/sys/add_app/', { package_name, name, sys_platform }, true)
    },
    editApp(id, package_name, name, exclusive, disable, sys_platform) {
        return fetch('/api/backdoor/sys/edit_app/', { id, package_name, name, exclusive, disable, sys_platform }, true)
    },
    removeApp(package_name) {
        return fetch('/api/backdoor/sys/remove_app/', { package_name }, true)
    },
    queryAppDetail(package_name, start_dt, end_dt, sys_platform) {
        return fetch('/api/backdoor/sys/query_app_detail/', { package_name, start_dt, end_dt, sys_platform }, true)
    },
    addTempIpWhitelist(ip) {
        return fetch('/api/backdoor/sys/add_temp_ip_whitelist/', { ip }, true)
    },
    queryPayDetail(pay_id) {
        return fetch('/api/backdoor/sys/query_pay_detail/', { pay_id }, true)
    }
}