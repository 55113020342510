import axios from 'axios'
import Qs from 'qs'
// import utils from '@/common/js/utils'
import vm from '@/main'


function getAjaxInstance(data) {
    if (data instanceof FormData) {
        // 文件请求
        return axios.create({
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRFToken': '',
            },
            timeout: 60 * 1000  // 60秒超时
        })
    } else {
        return axios.create({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': '',
            },
            timeout: 60 * 1000
        })
    }
}


function processData(data) {
    if (data instanceof FormData) {
        return data
    } else {
        return Qs.stringify(data)
    }
}


export function fetch(url, data, loading = false) {
    if (loading) {
        // utils.showLoading() 
    }
    return new Promise((resolve, reject) => {
        const instance = getAjaxInstance(data)
        data = processData(data)
        instance(Object.assign({url, method: 'post' }, { data }))
            .then(response => {
                let statusCode = response.data.status_code
                console.log(statusCode, url)
                if(statusCode === 200) {
                    resolve(response.data)
                } else if(statusCode === 410) {
                    vm.$router.push('/login')
                } else if(statusCode === 400) {
                    vm.$router.push('/login')
                } else {
                    if(response.data.desc) {
                        // utils.vueMessage(response.data.desc, 'error')
                        alert(response.data.desc)
                    } else {
                        // utils.vueMessage(`未知错误:${statusCode}`, 'error')
                        alert(`未知错误:${statusCode}`)
                    }
                }
            })
            .catch(error => {
                // utils.vueMessage('服务器内部错误', 'error')
                alert('服务器内部错误')
                console.log('请求异常信息:' + error)
                reject(error)
            })
            .finally(() => {
                // utils.hideLoading()
            })
    })
}

export function fileFetch(url, formData, options, loading = false) {
    for (let key in options) {
        formData.append(key, options[key])
    }
    return fetch(url, formData, loading)
}